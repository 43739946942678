import React, { Component } from 'react';
import { HashRouter, Route, Switch} from 'react-router-dom';

import './scss/style.scss';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {Session} from 'bc-react-session';
import {Redirect} from 'react-router-dom';

import Spinner from 'react-spinner-material';
import BounceLoader from "react-spinners/BounceLoader";

//import { browserHistory } from 'react-router'; // importing from 'react-router'
import OneSignal from 'react-onesignal';
import IdleTimer from 'react-idle-timer'


import cookie from 'react-cookies'
 
import GAevent from './views/GoogleAnalytics/EventTracking'
import GAexception from './views/GoogleAnalytics/ExceptionTracking'
import axios from 'axios';


const csrf = require('csrf-token')
var csrfToken;



const loading = (


   <center>
   <div className="sweet-loading"  style={{ display: 'block',marginTop:'20%'
           }}>
        <BounceLoader
          size={40}
          color={"#254c91"}
          loading={true}
          style={{ display: 'block',
       
           }}
        />
      </div>
    
   </center>

)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ForgotPassword = React.lazy(() => import('./views/pages/login/Forgot'));
const ResetPassword = React.lazy(() => import('./views/pages/login/ResetPassword'));
const ForgotUserid = React.lazy(() => import('./views/pages/login/ForgotUserid'));
const Changepassword = React.lazy(() => import('./views/pages/login/Changepassword'));
const ExternalFeedBackForm = React.lazy(() => import('./views/pages/feedbackForm/form'));
const ProcessPayment = React.lazy(() => import('./views/billing/ProcessPayment'));
const PaymentResult = React.lazy(() => import('./views/billing/PaymentResult'));


//var authenticated;
let authenticated;
class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      csrfToken:'',
      checkToken:'',
      theme:'light',

    }
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.toggleTheme = this.toggleTheme.bind(this)
  }
  
  
  toggleTheme = () => {
    alert('theme changed')
    if (this.state.theme === 'light') {
      this.setState({theme:'dark'})
    } else {
      this.setState({theme:'light'})
    }
  }

  handleOnAction (event) {
  //  console.log('user did something', event)
  }
 
  handleOnActive (event) {
  //  console.log('user is active', event)
  //  console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  handleOnIdle (event) {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())

    ///alert('inactive');
    //// expire token API call
GAevent('Expire Token','Expire Token');
var  apiBaseUrl = window.$SESSION_EXPIRY;
const { payload } = Session.get();
var data1={
  "username":payload.username,
  "sessionid": localStorage.getItem("sessionid"),
  "token":localStorage.getItem("token"),
  "expiry": "SINGLE_SESSION",
  }


   axios.post(apiBaseUrl, data1)
.then(function (response) {

console.log("response from expire token API");
var res=response;
console.log(res);
var msg = res['data'];
console.log(msg);

Session.destroy();
localStorage.setItem("sessionDestroyed",true);
localStorage.setItem("sessionid",'');
authenticated=false;

})
.catch(function (error) {
console.log(error);
GAexception("Token Expiration Failed");

});
//// expire token API ends

  }



  componentDidMount(){

    const $this=this;
      ////csrf token
  csrf.create('csrfToken', (err, token) => {
    if (err) console.error(err)
    else{ console.log(`csrf token '${token}'`)
    cookie.save('csrftoken', token,  { path: '/' });
    $this.setState({csrfToken:token})
    $this.setState({checkToken:token})
    csrfToken=token;
    console.log('generated_token:'+csrfToken)
    
  }
  })
  console.log('generated_token:'+csrfToken)

  ////
  }


  render() {
    

    /////
////let authenticated;
let userType;
let userName;
let password;
let url;
let currentTime;
let loginTime;


currentTime=new Date;
    //let authenticated=false;
   const session = Session.get();
    console.log(session.isValid);

    const {payload} = Session.get();

console.log(payload.username);
    
    userName=payload.username;
    password=payload.password;
    userType=payload.userType;
    //
    if(session.isValid && session.active)
{

  
  loginTime=payload.loginTime;

  
  let diff =(currentTime.getTime() - new Date(loginTime).getTime()) / 1000;
  diff /= 60;
  diff= Math.abs(Math.round(diff));
 
  //alert(diff);
  if(diff>10){
   // Session.destroy();
   // authenticated=false;
  }


  //else
 authenticated=true;

 
 
    //// destroy session on expiration
   // Session.onExpiration((session) => session.destroy());

}


else
authenticated=false;
console.log(session.isValid);

console.log(authenticated);

console.log(document.getElementById('myForm'));
console.log(document.getElementById('test'));

console.log('before_default_routing', window.location.href)

if(authenticated&&userType=='AUDIOCON'){
  url='https://customer.nayatel.com/CustomerPortalWeb/audiocondashboard_new.php';

  
  let my_form=document.createElement('FORM');
  my_form.name='myForm';
  my_form.method='POST';
  my_form.action=url;
  
  let my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='username';
  my_tb.value=userName;
  my_form.appendChild(my_tb);
  
  my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='password';
  my_tb.value=password;
  my_form.appendChild(my_tb);
  document.body.appendChild(my_form);


  my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='userType';
  my_tb.value=userType;
  my_form.appendChild(my_tb);
  document.body.appendChild(my_form);


  my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='sessionExists';
  my_tb.value=authenticated;
  my_form.appendChild(my_tb);
  document.body.appendChild(my_form);


  my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='csrfToken';
  my_tb.value=this.state.csrfToken;
  my_form.appendChild(my_tb);
  document.body.appendChild(my_form);



  // if(cookie.load('csrftoken')!=this.state.checkToken){
  //   csrf.create('csrfToken', (err, token) => {
  //     if (err) console.error(err)
  //     else{ console.log(`csrf token '${token}'`)
  //     cookie.save('csrftoken', token,  { path: '/' });
  //     this.setState({csrfToken:token})
  //     this.setState({checkToken:token})
  //     csrfToken=token;
  //     console.log('generated_token:'+csrfToken)
  //     my_form.submit();
  //   }
  //   })
  //   console.log('generated_token:'+csrfToken)
  // }
  
  // else
  my_form.submit();
}

else if(authenticated&&userType=='Kasperski'){
  url='https://customer.nayatel.com/CustomerPortalWeb/kasperskyDashboard_test.php';

  
  let my_form=document.createElement('FORM');
  my_form.name='myForm';
  my_form.method='POST';
  my_form.action=url;
  
  let my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='username';
  my_tb.value=userName;
  my_form.appendChild(my_tb);
  
  my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='password';
  my_tb.value=password;
  my_form.appendChild(my_tb);
  document.body.appendChild(my_form);


  my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='userType';
  my_tb.value=userType;
  my_form.appendChild(my_tb);
  document.body.appendChild(my_form);


  my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='sessionExists';
  my_tb.value=authenticated;
  my_form.appendChild(my_tb);
  document.body.appendChild(my_form);

  my_tb=document.createElement('INPUT');
  my_tb.type='HIDDEN';
  my_tb.name='csrfToken';
  my_tb.value=this.state.csrfToken;
  my_form.appendChild(my_tb);
  document.body.appendChild(my_form);



  // if(cookie.load('csrftoken')!=this.state.checkToken){
  //   csrf.create('csrfToken', (err, token) => {
  //     if (err) console.error(err)
  //     else{ console.log(`csrf token '${token}'`)
  //     cookie.save('csrftoken', token,  { path: '/' });
  //     this.setState({csrfToken:token})
  //     this.setState({checkToken:token})
  //     csrfToken=token;
  //     console.log('generated_token:'+csrfToken)
  //     my_form.submit();
  //   }
  //   })
  //   console.log('generated_token:'+csrfToken)
  // }
  
  // else
  my_form.submit();
}

else if(authenticated&&(userType=='LIVE-OTT' || userType=='NAYATV')){

    //routing Live OTT customers to naya tv website (16-05-2022)
    window.open("https://nayatv.nayatel.com/");

    
//   url='https://customer.nayatel.com/CustomerPortalWeb/nayatvdashboard_test.php';

  
//   let my_form=document.createElement('FORM');
//   my_form.name='myForm';
//   my_form.method='POST';
//   my_form.action=url;
  
//   let my_tb=document.createElement('INPUT');
//   my_tb.type='HIDDEN';
//   my_tb.name='username';
//   my_tb.value=userName;
//   my_form.appendChild(my_tb);
  
//   my_tb=document.createElement('INPUT');
//   my_tb.type='HIDDEN';
//   my_tb.name='password';
//   my_tb.value=password;
//   my_form.appendChild(my_tb);
//   document.body.appendChild(my_form);


//   my_tb=document.createElement('INPUT');
//   my_tb.type='HIDDEN';
//   my_tb.name='userType';
//   my_tb.value=userType;
//   my_form.appendChild(my_tb);
//   document.body.appendChild(my_form);


//   my_tb=document.createElement('INPUT');
//   my_tb.type='HIDDEN';
//   my_tb.name='sessionExists';
//   my_tb.value=authenticated;
//   my_form.appendChild(my_tb);
//   document.body.appendChild(my_form);



//   my_tb=document.createElement('INPUT');
//   my_tb.type='HIDDEN';
//   my_tb.name='csrfToken';
//   my_tb.value=this.state.csrfToken;
//   my_form.appendChild(my_tb);
//   document.body.appendChild(my_form);


//  my_form.submit();
}

else if(authenticated&&userType=='Reseller'){

  window.open('https://portal.nayatel.com/');
}
else if(authenticated&&userType=='EXITLAG' && !window.location.href.includes('processPayment') ){
  // window.location.href = 'http://localhost:5173/exitLag/exitlag/dashboard'
  window.location.href = 'https://customer.nayatel.com/CP/exitlag/dashboard'
}

    return (

      <div>
         <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 10}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
     
      <HashRouter  >
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/processPayment" name="ProcessPayment" render={props => <ProcessPayment {...props}/>} />
              <Route exact path="/paymentResult" name="paymentResult" render={props => <PaymentResult {...props}/>} />

              <Route path="/login" name="Login Page" render={props => <Login {...props}/>} />
            
            
            



<Route path="/login" render={() => {
  console.log(authenticated)
  console.log('before_exitlag_appjs')
  if(authenticated && payload.userType=='EXITLAG') {
    console.log('exitlag_appjs')
       window.location.href = 'https://customer.nayatel.com/CP/exitlag/dashboard'
  }
  return (
    authenticated?
    <Redirect to="/dashboard" /> :
    <Redirect to="/login" /> 
  )
}}/>


<Route exact path="/feedback" name="External Feedback Page" render={props => <ExternalFeedBackForm {...props}/>} />
         
         
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />

           {/*  <Route path="/" name="Dashboard" render={props => <TheLayout {...props}/>} />*/}


           <Route  path="/dashboard" name="Dashboard" render={props => <TheLayout {...props}/>} />
           
           
           
          
           <Route path="/support" name="Support" render={props => <TheLayout {...props}/>} />
           <Route path="/launchComplaint" name="TicketGenerationForm" render={props => <TheLayout {...props}/>} />

           <Route path="/billing" name="Billing" render={props => <TheLayout {...props}/>} />
           <Route path="/onlinePayment" name="OnlinePayment" render={props => <TheLayout {...props}/>} />

           <Route path="/nwatch" name="NWatch" render={props => <TheLayout {...props}/>} />
           
           <Route path="/internetServices" name="InternetServices" render={props => <TheLayout {...props}/>} />
           <Route path="/videoServices" name="VideoServices" render={props => <TheLayout {...props}/>} />
           <Route path="/phoneServices" name="PhoneServices" render={props => <TheLayout {...props}/>} />
           <Route path="/otherServices" name="OtherServices" render={props => <TheLayout {...props}/>} />
           <Route path="/subscribeService" name="ServiceDetailsContainer" render={props => <TheLayout {...props}/>} />
           <Route path="/safewebSettings" name="SafeWebSettings" render={props => <TheLayout {...props}/>} />
           <Route path="/optimus" name="Optimus" render={props => <TheLayout {...props}/>} />
    
           <Route path="/installationStatus" name="InstallationTracker" render={props => <TheLayout {...props}/>} />
           <Route path="/cloudPBX" name="CloudPbx" render={props => <TheLayout {...props}/>} />
           <Route path="/myDetails" name="MyDetails" render={props => <TheLayout {...props}/>} />
           <Route path="/payWithBykea" name="PayWithBykea" render={props => <TheLayout {...props}/>} />
           <Route path="/bykeaRiderStatus" name="BykeaRiderStatus" render={props => <TheLayout {...props}/>} />
           <Route path="/ITConsultancy" name="DataTrend" render={props => <TheLayout {...props}/>} />
           <Route path="/events" name="NwatchEvents" render={props => <TheLayout {...props}/>} />
           <Route path="/connectedDevices" name="ConnectedDevices" render={props => <TheLayout {...props}/>} />
           <Route path="/optimizeNetwork" name="NetworkOptimization" render={props => <TheLayout {...props}/>} />
           <Route path="/speedTest" name="SpeedTest" render={props => <TheLayout {...props}/>} />
           <Route path="/wifiSettings" name="WifiSettings" render={props => <TheLayout {...props}/>} />
           <Route path="/viewUserActivity" name="ActivityHistory" render={props => <TheLayout {...props}/>} />
           <Route path="/userProfile" name="UserProfile" render={props => <TheLayout {...props}/>} />
           <Route path="/notifications" name="Notifications" render={props => <TheLayout {...props}/>} />
           <Route path="/settings" name="Settings" render={props => <TheLayout {...props}/>} />
           <Route path="/privacyPolicy" name="PrivacyPolicy" render={props => <TheLayout {...props}/>} />
           <Route path="/consentForm" name="ConsentForm" render={props => <TheLayout {...props}/>} />

{/* */}
<Route exact path="/"

render={() => {
    if(authenticated && payload.userType=='EXITLAG')
       window.location.href = 'https://customer.nayatel.com/CP/exitlag/dashboard'
  return (
   authenticated ?
   <div>

{/*testing data trend */}

  {payload.userType!='CLOUDPBX-PREPAID'&&!payload.dataTrend&&payload.userType!='EXITLAG'?(
    <Redirect to="/dashboard" /> 
    ):
    null
    }
    
  {payload.dataTrend?(     
    <Redirect to="/ITConsultancy" /> 
    ):
    null
    }

    {payload.userType=='CLOUDPBX-PREPAID'?(
     <Redirect to="/cloudPBX" /> 
    ):
    null
    }
    
    
    </div>:
    <Redirect to="/login" /> 
  )
}}

/>



{/* <Route  path="/dashboard"

render={() => {
  console.log(authenticated)
  return (
   authenticated ?
    <Redirect to="/dashboard" /> :
    <Redirect to="/login" /> 
  )
}}

/>


<Route  path="#/dashboard"

render={() => {
  return (
   authenticated ?
    <Redirect to="/dashboard" /> :
    <Redirect to="/login" /> 
  )
}}

/> */}


<Route path="/billing"
  render={() => {
  return (
   authenticated ?
    <Redirect to="/billing" /> :
    <Redirect to="/login" /> 
  )
  }}
/>


<Route path="/onlinePayment"
  render={() => {
  return (
   authenticated ?
    <Redirect to="/onlinePayment" /> :
    <Redirect to="/login" /> 
  )
 }}
/>


<Route  path="/support"
render={() => {
  return (
   authenticated ?
    <Redirect to="/support" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route  path="/launchComplaint"
render={() => {
  return (
   authenticated ?
    <Redirect to="/launchComplaint" /> :
    <Redirect to="/login" /> 
  )
}}
/>


<Route  path="/nwatch"

render={() => {
  return (
   authenticated ?
    <Redirect to="/nwatch" /> :
    <Redirect to="/login" /> 
  )
}}

/>



<Route  path="/installationStatus"

render={() => {
  return (
   authenticated ?
    <Redirect to="/installationStatus" /> :
    <Redirect to="/login" /> 
  )
}}

/>


{/* pay with bykea  */}
<Route  path="/payWithBykea"

render={() => {
  return (
   authenticated ?
    <Redirect to="/payWithBykea" /> :
    <Redirect to="/login" /> 
  )
}}

/>


 {/* bykea status */}
 <Route  path="/bykeaRiderStatus"

render={() => {
  return (
   authenticated ?
    <Redirect to="/bykeaRiderStatus" /> :
    <Redirect to="/login" /> 
  )
}}

/>


<Route path="/internetServices"
  render={() => {
  return (
   authenticated ?
    <Redirect to="/internetServices" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/videoServices"
  render={() => {
  return (
   authenticated ?
    <Redirect to="/videoServices" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/phoneServices"
  render={() => {
  return (
   authenticated ?
    <Redirect to="/phoneServices" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route  path="/otherServices"
 render={() => {
  return (
   authenticated ?
    <Redirect to="/otherServices"/> :
    <Redirect to="/login" /> 
   )
  }}
/>

<Route path="/subscribeService"
  render={() => {
  return (
   authenticated ?
    <Redirect to="/subscribeService" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/safewebSettings"
  render={() => {
  return (
   authenticated ?
    <Redirect to="/safewebSettings" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/optimus"

render={() => {
  return (
   authenticated ?
    <Redirect to="/optimus" /> :
    <Redirect to="/login" /> 
  )
}}

/>


<Route path="/events"

render={() => {
  return (
   authenticated ?
    <Redirect to="/events" /> :
    <Redirect to="/login" /> 
  )
}}

/>

<Route path="/connectedDevices"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/connectedDevices" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/optimizeNetwork"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/optimizeNetwork" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/speedTest"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/speedTest" /> :
    <Redirect to="/login" /> 
  )
}}
/>


<Route path="/wifiSettings"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/wifiSettings" /> :
    <Redirect to="/login" /> 
  )
}}
/>


<Route path="/viewUserActivity"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/viewUserActivity" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/userProfile"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/userProfile" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/notifications"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/notifications" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/settings"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/settings" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/privacyPolicy"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/privacyPolicy" /> :
    <Redirect to="/login" /> 
  )
}}
/>

<Route path="/consentForm"
  render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/consentForm" /> :
    <Redirect to="/login" /> 
  )
}}
/>

{/* <Route path="/cloudPBX"

render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/cloudPBX" /> :
    <Redirect to="/login" /> 
  )
}}

/> */}


<Route path="/myDetails"

render={() => {
  console.log(authenticated);
  return (
   authenticated ?
    <Redirect to="/myDetails" /> :
    <Redirect to="/login" /> 
  )
}}

/>




         {/* <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />*/}

          <Route exact path="/forgotPassword" name="ForgotPassword" render={props => <ForgotPassword {...props}/>} />
          <Route exact path="/resetPassword" name="ResetPassword" render={props => <ResetPassword {...props}/>} />
          <Route exact path="/forgotUserid" name="ForgotUserid" render={props => <ForgotUserid {...props}/>} />
          <Route  path="/changePassword" name="Changepassword" render={props => <Changepassword {...props}/>} />

          {/* <Route exact path="/processPayment" name="ProcessPayment" render={props => <ProcessPayment {...props}/>} />
          <Route exact path="/paymentResult" name="paymentResult" render={props => <PaymentResult {...props}/>} /> */}
        

           </Switch>
          </React.Suspense>
      </HashRouter>
      </div>

    
    );
  }
}

export default App;
