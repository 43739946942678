export const NetworkCallsRoutes=()=>{
  window.$PORTAL_BASE_URL = "https://api.nayatel.com/es/CustomerPortalApis/";
  window.$PORTAL_BASE_URL_CS = "https://api.nayatel.com/creatives/CustomerPortal/";
  
  window.$SPEEDUP_HOME_NORMAL = window.$PORTAL_BASE_URL  + "BOD/BOD_Normal_Auth.php";
  window.$SPEEDUP_HOME_NIGHT = window.$PORTAL_BASE_URL  + "BOD/BOD_Night_Auth.php";
  window.$SPEEDUP_HOME_NIGHT_RECURSIVE = window.$PORTAL_BASE_URL  + "BOD/BOD_Night_Recursive_Auth.php";
  window.$SPEEDUP_VERIFY_HOME = window.$PORTAL_BASE_URL  + "BOD/verifyBod_Auth.php";
  window.$SPEEDUP_CONNECT = window.$PORTAL_BASE_URL  + "BOD/createNewupload_Auth.php";
  window.$SPEEDUP_VERIFY_CONNECT = window.$PORTAL_BASE_URL  + "BOD/verifyBod2_Auth.php";

  window.$EXTRA_GBS = "https://customer.nayatel.com/extragbs/extragbsAJAX_GBS_Auth.php";
  window.$EXTRA_GBS_FSD = "https://customer.nayatel.com/extragbs/extragbsFSDAJAX_Auth.php";

 
  window.$EXIT_LAG = "https://customer.nayatel.com/CustomerSignup_Exitlag/csa_ajax.php";

  //user profile
  window.$USER_PROFILE = "https://customer.nayatel.com/MyNayatelApp/EditContactDetailsAPI.php";

  //notifications
  window.$NOTIFICATIONS_HISTORY = "https://myapp.nayatel.com:81/CustomerPortal/notificationsHistory.php";
  // window.$NOTIFICATIONS_HISTORY = "https://myapp.nayatel.com:81/CustomerPortal/notificationsHistory_dev.php";
  window.$NOTIFICATIONS_SETTINGS = "https://customer.nayatel.com/MyNayatelApp/PushNotificationAPI.php";

  //feedback
  window.$CHECK_FEEDBACK_EXPIRY = "https://api.nayatel.com/es/CustomerPortalApis/Feedbacks/checkFeedbackLinkValidity.php"
  window.$FEEDBACK_URL = "https://customer.nayatel.com/ajaxFiles/feedbackform.php"


  //APIs on API server

  //login
  window.$LOGIN =  window.$PORTAL_BASE_URL + "login.php";


  //customer details
  window.$LANDING_DATA =  window.$PORTAL_BASE_URL + "CustomerDetails/landingData.php";


  //billing
  window.$LOYALTY_POINTS_DETAILS =  window.$PORTAL_BASE_URL + "Billing/loyaltyPointsDetail.php";
  window.$AUTOBONUS =  window.$PORTAL_BASE_URL + "Billing/autoBonus.php";
  window.$PAYMENT_RECEIPT =  window.$PORTAL_BASE_URL + "Billing/paymentReceipt.php";
  window.$TAX_CERTIFICATE =  window.$PORTAL_BASE_URL + "Billing/taxCertificate.php";
  window.$BILL_HISTORY =  window.$PORTAL_BASE_URL + "Billing/billHistory.php";
  window.$INVOICE =  window.$PORTAL_BASE_URL + "Billing/invoice.php";
  window.$PDF_INVOICE_PATH = window.$PORTAL_BASE_URL + "invoiceview/invoicePDF/";
  window.$PAYMENT_INTEGRATION_API = "https://crm.nayatel.com/views/crmViews/nayatelCrm/digitalSignagePaymentAPI/paymentIntegrationAPI.php";
  window.$ALFALAH_GATEWAY_API = "https://api.nayatel.com/creatives/BankAlfalah/gatewayApis/gatewayApi_v2.php";
  window.$ALFALAH_PAYMENT_LOGS = "https://api.nayatel.com/creatives/BankAlfalah/bankAlfalahLogs/paymentLogs_v2.php";
  window.$INVOICE_SUMMARY = window.$PORTAL_BASE_URL + "Billing/InvoiceSummary/invoiceSummary.php";
  window.$AUTO_DEBIT =  window.$PORTAL_BASE_URL + "Billing/AutoDebit/autoDebitStatus.php";

  //reports and info
  window.$INTERNET_USAGE_HISTORY =  window.$PORTAL_BASE_URL + "Reports/internetUsageHistory.php";
  window.$DETAIL_SESSION_REPPORT =  window.$PORTAL_BASE_URL + "Reports/DetailSessionReport.php";
  window.$MOBILE_OFFER_CDR =  window.$PORTAL_BASE_URL + "Reports/MobileOfferCdr.php";
  window.$MOBILE_OFFER_SUMMARY =  window.$PORTAL_BASE_URL + "Reports/mobileOfferSummary.php";
  window.$CALL_DETAIL_RECORD =  window.$PORTAL_BASE_URL + "Reports/callDetailRecord.php";
  window.$VOLUME_TRANSFER_HISTORY =  window.$PORTAL_BASE_URL + "Reports/volumeTransferReport.php";
  window.$AUDIOCON_HISTORY =  window.$PORTAL_BASE_URL + "Reports/audioconHistory.php";
  window.$PARENTAL_LOCKING_REPORT =  window.$PORTAL_BASE_URL + "Reports/parentalLockingReport.php";
  window.$SPEED_UP_REPORT =  window.$PORTAL_BASE_URL + "Reports/speedUpReport.php";
  window.$MOBILE_OFFER_SUBSCRIBED_NUMBERS = window.$PORTAL_BASE_URL + "Reports/mobileOfferSubscribedNumbers.php";

  //services
  window.$CANCEL_AUDIOCON_RESERVATION =  window.$PORTAL_BASE_URL + "Services/Audiocon/cancelAudioconReservation.php";
  window.$CANCEL_PARENTAL_LOCKING_SESSION =  window.$PORTAL_BASE_URL + "Services/ParentalLocking/cancelParentalLockingSession.php";
  window.$CANCEL_SPEED_UP_RECURSIVE =  window.$PORTAL_BASE_URL + "Services/SpeedUp/speedUpRecursiveCancellation.php";
  window.$SPEED_UP_HOME_VERIFICATION =  window.$PORTAL_BASE_URL + "Services/SpeedUp/speedUpHomeVerification.php";
  window.$SPEED_UP_CONNECT_VERIFICATION =  window.$PORTAL_BASE_URL + "Services/SpeedUp/speedUpConnectVerification.php";
  
 //support
  // window.$SUPPORT_TIERS_DETAILS =  window.$PORTAL_BASE_URL + "Support/TT_Tiers.php";
  window. $SUPPORT_TIERS_DETAILS = "https://devapi.nayatel.com/es/CustomerPortalApis/Support/TT_Tiers_New.php";
  //network management
  window.$NETWORK_MANAGEMENT = window.$PORTAL_BASE_URL_CS + "NetworkManagement/smartWifi.php";
  // window.$NETWORK_MANAGEMENT = window.$PORTAL_BASE_URL_CS + "NetworkManagement/smartWifi_dev.php"; //for testing
  window.$WIFI_SETTINGS = "https://customer.nayatel.com/wifiPassChangeAPI/wifiSettings.php";


  //user activity history
  window.$USER_ACTIVITY_HISTORY = window.$PORTAL_BASE_URL_CS + "ActivityLog/activityLog.php";
  // window.$USER_ACTIVITY_HISTORY = window.$PORTAL_BASE_URL_CS + "ActivityLog/activityLog_dev.php"; //for testing

  // search list
  window.$SEARCH_LIST = window.$PORTAL_BASE_URL_CS + "Support/searchItem.php"; 
  // window.$SEARCH_LIST = window.$PORTAL_BASE_URL_CS + "Support/searchItem_dev.php";


  //feedback
  window.$GENERIC_FEEDBACK = "https://customer.nayatel.com/CustomerPortalWeb/crmCustomer/feedbackAPI_Auth.php";
  

  //services
  window.$VideoVAS = "https://devapi.nayatel.com/es/CustomerPortalApis/Services/VideoServices/VideoServices.php"

  //nwatch
  window.$NWATCH_RECORDINGS = "https://api.nayatel.com/creatives/NWatch/BridgeApis/nwatchRecording.php";
  // window.$NWATCH_RECORDINGS = "https://api.nayatel.com/creatives/NWatch/BridgeApis/nwatchRecording_dev.php"; //for testing
  window.$NWATCH_RECORDINGS_MP4 = "https://customer.nayatel.com/getUserRecording_Auth_st.php";
  // window.$NWATCH_RECORDINGS_MP4 = "https://devcustomer.nayatel.com/getUserRecording_Auth_st.php"; //for testing
  window.$SNWATCH_ALERTS = "https://customer.nayatel.com/snwatchapis/getAlerts.php";

  
  //logs
  window.$PORTAL_LOGS = window.$PORTAL_BASE_URL_CS + "Logs/customerPortalLogs.php";
  // window.$PORTAL_LOGS = window.$PORTAL_BASE_URL_CS + "Logs/customerPortalLogs_dev.php";  //testing API
  // window.$PORTAL_LOGS = "https://devapi.nayatel.com/creatives/CustomerPortal/Logs/customerPortalLogs_dev.php";  //testing API

  //session expiry
  window.$SESSION_EXPIRY =  window.$PORTAL_BASE_URL + "expireToken.php";



  //variables
  window.$ALFALAH_SDK_JS = "https://bankalfalah.gateway.mastercard.com/checkout/version/62/checkout.js";
  window.$UBL_GATEWAY = "https://customer.nayatel.com/OnlinePaymentCCAutoDebit/processPayment.php";

}

export default NetworkCallsRoutes