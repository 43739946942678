import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

 const GAevent = (categoryName, eventName) => {
    ReactGA.event({       
        category: categoryName,  // Required
        action: eventName,       // Required
        label: 'labelName',       
        value: 10,       
        nonInteraction: false     
    });   
}
export default GAevent;