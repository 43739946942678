import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'

///// @@@@

import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import { sessionService, sessionReducer } from 'redux-react-session';
import thunkMiddleware from 'redux-thunk';
////@@@@
import NetworkCallsRoutes from './NetworkCallsRoutes';



React.icons = icons


///@@@@

// Add the sessionReducer
const reducer = combineReducers({
  session: sessionReducer
});

const store1 = createStore(reducer, undefined, compose(applyMiddleware(thunkMiddleware)));

// Init the session service
sessionService.initSessionService(store1);
////@@@@


if (process.env.NODE_ENV !== "development")
//commented for testing
  console.log = () => {};  ///hide logs for production build
 ///


 //API's End points
 NetworkCallsRoutes();
 /* **************** */



ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
